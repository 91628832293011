<template>
    <div style="width: 100%;height: 100%">
        <div style="width: 100%;background: #FFFFFF;">
            <div style="width: 1500px;height: 80px;display: flex;align-items: center;justify-content: space-between;margin: 0 auto;background: #FFFFFF" >
                <div style="display: flex;align-items: center;">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertSpace/Expertspace.png" style="height: 49px;width: auto;margin: 0 10px;"/>

                    <div style="width: 600px;background: #FFFFFF"></div>
                </div>
                <div style="display: flex;align-items: center;">
                    <AvatarMenu></AvatarMenu>
<!--                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/notice.png" style="height: 22px;width: auto"/>-->

<!--                    <img :src="user.headIco" style="height: 30px;width: 30px;margin: 0 10px 0 40px;border-radius: 50%;"/>-->

<!--                    <span style="margin-right: 10px;font-size: 18px;">{{user.nickName}}</span>-->

<!--                    <i class="el-icon-caret-bottom"></i>-->

                </div>
            </div>
        </div>
        <div style="width: 100%; display: flex; justify-content: center; ">
            <div style="width: 1500px; display: flex;">
                <div style="width: 1120px;margin-top: 10px;">
                    <div style="width: 1120px; min-height: 200px; background: #FFFFFF; border-radius: 5px;box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);">
                         <spatialInformation :expertinfo="expertinfo"></spatialInformation>
                    </div>
                    <div style="width: 1120px; min-height: 1200px; background: #FFFFFF; margin-top: 10px; border-radius: 5px;box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);">
                         <expertOrder :expertinfo="expertinfo"></expertOrder>
                    </div>
                </div>
                <!--  专家话题列表 -->
                <div v-if="$route.params.uuid == 0" style="width: 370px;margin-left: 10px;margin-top: 10px;">
                    <consultationTopic :expertinfo="MyExpertTopic" @cancel-edit="canceledit"></consultationTopic>
                </div>
                <div v-else>
                    <!--  选择咨询话题  -->
                    <div style="width:370px; min-height: 420px;margin-left: 10px;margin-top: 10px;">
                        <chooseATopic :expertinfo="expertinfo"></chooseATopic>
                    </div>
                    <!--  推荐专家   -->
                    <div style="width: 370px; min-height: 360px;margin-left: 10px;margin-top: 10px;">
                        <recommendExperts></recommendExperts>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import spatialInformation from "./componentsSpace/spatialInformation"
import consultationTopic from "./componentsSpace/consultationTopic"
import expertOrder from  "./componentsSpace/expertOrder"
import recommendExperts from "./componentsSpace/recommendExperts"
import chooseATopic from "./componentsSpace/chooseATopic"
import AvatarMenu from "../../components/common/AvatarMenu";
export default {
    components: {
        spatialInformation,
        consultationTopic,
        expertOrder,
        recommendExperts,
        chooseATopic,
        AvatarMenu
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        is_login() {
            return this.$store.state.is_login
        }
    },
    data() {
        return {
            expertinfo:{},
            MyExpertTopic:[]
        }
    },

    mounted() {
        this.getExpertInfo();
        // 跳转在顶部
        window.scrollTo(0, 0);
        if(this.is_login === 1){
            this.getMyExpertTopic()
        }
    },

    methods: {
        // 获取专家详情
        getExpertInfo(){
            var that = this;
            var params = {}
            if(that.$route.params.uuid != 0){
                params.uuid = that.$route.params.uuid
            }
            that.newApi.getExpertInfo(params).then(res => {
                for (let i = 0;i<res.data.topicList.length;i++){
                    res.data.topicList[i]['topicSwitch']=false
                }
                that.expertinfo = res.data;
            }).catch((err) => {
                console.log(err)
            })
        },
        attentionstatus(){
           this.getExpertInfo()
        },
        canceledit(){
            this.getMyExpertTopic()
        },
        // 获取我的专家话题
        getMyExpertTopic() {
            var that = this;
            that.newApi.getUserInfo({}).then(res => {
                that.typeuser = res.data.uuid;
                var params = {};
                params.uuid = that.typeuser;
                that.newApi.getMyExpertTopic(params).then(res => {
                    // for (let i = 0; i < res.data.MyExpertTopic.length; i++) {
                    //     res.data.MyExpertTopic[i]['topicSwitch'] = false;
                    // }
                    res.data.forEach(item => {
                        item.topicSwitch = false;
                    });
                    that.MyExpertTopic = res.data;
                }).catch((err) => {
                    console.log(err);
                });
            });
        },


    }
}
</script>