<template>
    <div style="width: 100%; height: 100%;">
        <div style="position: relative">
            <div style="width: 100px; height: 100px; background: #FFFFFF; border-radius: 50%; box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.26); display: flex; align-items: center; justify-content: center;position: absolute;top: 24px;left: 30px">
                <div style="width: 88px; height: 88px;">
                    <img style="border-radius: 50%; width: 88px; height: 88px;" :src="expertinfo?.headIco">
                </div>
            </div>
            <div style="position: absolute;left: 170px;top: 41px;float: left">
              <div style="display: flex">
                  <div><span style="font-size: 26px;font-weight: 600;">{{ expertinfo?.nickName }}</span></div>
                  <div v-if="expertinfo.v == 1" style="margin-left: 20px;margin-top: 5px;"><img src="../../../assets/imgs/index/vipBig.png" style="width: 32px;height: 27px;"></div>
                  <div v-if="expertinfo.realNameStatus == 2" style="margin-left: 10px;margin-top: 5px;"><img src="../../../assets/imgs/index/realName.png" style="width: 70px;height: 27px;"></div>
                  <div v-if="expertinfo.authenticationStatus  == 2" style="margin-left: 10px;margin-top: 5px;"><img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertSpace/major.png" style="width: 66px;height: 27px;"></div>
                  <div v-if="expertinfo.expertAuthStatus == 2" style="margin-left: 10px;margin-top: 5px;"><img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertSpace/inoc.png" style="width: 66px;height: 27px;"></div>
              </div>
                <div style="font-size: 18px;margin-top: 20px;color: #999999">
                    <span v-if="expertinfo.address && expertinfo.address.cityName">{{expertinfo.address.cityName[0]}}</span>
                    <span  v-if="expertinfo.address && expertinfo.address.cityName" style="margin: 0px 10px"> | </span>
                    <span v-if="expertinfo.address && expertinfo.address.cityName">{{expertinfo.address.cityName[1]}}</span>
                </div>
                <div style="display: flex;justify-content: space-around">
                    <div style="margin-top: 30px;font-size: 18px">
                        <div style="border-radius: 50%;width: 6px;height: 6px;background: #666666;float: left;margin-top: 10px"></div>
                        <span style="float: left;margin-left: 10px">帮助过：</span>
                        <span style="color: #2970FF;float: left;margin-top: 1px">1314人</span>
                    </div>
                    <div style="font-size: 18px;margin: 30px 60px;overflow: hidden">
                        <div style="border-radius: 50%;width: 6px;height: 6px;background: #666666;float: left;margin: 10px"></div>
                        <span style="float: left">响应率：</span>
                        <span style="color: #2970FF;float: left;margin-top: 1px">99%</span>
                    </div>
                    <div style="margin-top: 30px;font-size: 18px">
                        <div style="border-radius: 50%;width: 6px;height: 6px;background: #666666;float: left;margin: 10px"></div>
                        <span style="float: left">完成订单数：</span>
                        <span style="color: #2970FF;float: left;margin-top: 1px">520单</span>
                    </div>
                </div>

            </div>
            <div v-if="$route.params.uuid == 0" @click="a('/expert/become/')" class="hand op sc" style="width: 128px;height: 36px;float: right;margin: 43px 30px;">
                <el-button style="background:#2970FF;color: #FFFFFF;width: 128px;height: 36px;padding: 9px 20px;">查看专家信息</el-button>
            </div>
            <div v-else style="display: flex;float: right;margin: 43px 30px;">
                <div class="hand op sc" v-if="expertinfo.isFollow == 1" @click="inviteexperts = true" style="width: 128px;height: 36px;margin-right:20px">
                    <el-button style="background:#FFFFFF;color: #0C268C;border: 1px solid #0C268C;font-weight: bold;font-size: 18px;width: 128px;height: 36px;padding: 9px 20px;">邀请专家</el-button>
                </div>
                <div class="hand op sc" @click="collectionExpert" style="width: 128px;height: 36px;">
                    <el-button v-if="expertinfo.isFollow == 0" style="background:#FFFFFF;color: #2970FF;border: 1px solid #2970FF;font-weight: bold;font-size: 18px;width: 128px;height: 36px;padding: 9px 20px;">+关注专家</el-button>
                    <el-button v-if="expertinfo.isFollow == 1" style="background:#FFFFFF;color: #2970FF;border: 1px solid #2970FF;font-weight: bold;font-size: 18px;width: 128px;height: 36px;padding: 9px 20px;">已关注</el-button>
                </div>
            </div>
            <el-dialog :close-on-click-modal="false" :visible.sync="inviteexperts"  width="950px">
                <inviteExperts></inviteExperts>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import inviteExperts from './inviteExperts'
export default {
    components: {
        inviteExperts
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    data() {
        return {
            inviteexperts:false
        }
    },
    props: {
        expertinfo:{
            type:Object,
            default() {
                return {};
            }
        },
    },

    mounted() {

    },

    methods: {
        // 关注专家
        collectionExpert(){
                if (this.user.realNameStatus === 2) {
                    this.newApi.collectionExpert({
                        uuid: this.$route.params.uuid
                    }).then(res => {
                        if (res.isSuccess == 1) {
                            this.utils.sus(res.data)
                            this.$parent.attentionstatus()
                        }
                    })
                }else {
                    this.utils.err('请先实名再关注专家！')
                }
            },
        inviteexpertscode(){
                this.inviteexperts = false
            }
        }

}
</script>
<style scoped>
/deep/ .el-dialog__body {
    padding: 0px 0px !important;
}
</style>