<template>
    <div style="width: 100%">
        <div style="width: 370px;height: 420px;background: #FFFFFF;box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);border-radius: 5px;">
            <div style="padding-top: 24px;">
                <div style="font-size: 18px;font-weight: 600;margin-left: 30px;float: left">选择咨询话题</div>
            </div>
            <div style="padding: 12px 30px;clear: both">
                <el-select style="width: 310px;height: 46px;" v-model="selectedItem" placeholder="选择专家咨询话题">
                    <el-option
                        v-for="item in expertinfo.topicList"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div style="padding-top: 15px;">
                <div style="font-size: 18px;font-weight: 600;margin-left: 30px;float: left">选择咨询方式</div>
            </div>
            <div style="padding: 30px;">
                <el-radio-group class="space" v-model="radio">
                    <el-radio class="space hand op sc" :label="1">视频咨询<span style="font-size: 15px;color: #999999">（专家1对1在线上通话）</span></el-radio>
                    <el-radio class="space hand op sc" :label="2">在线复函<span style="font-size: 15px;color: #999999">（专家在线回复）</span></el-radio>
                    <el-radio class="space hand op sc" :label="3">线下服务<span style="font-size: 15px;color: #999999">（邀请专家到现场服务）</span></el-radio>
                </el-radio-group>
            </div>
            <div style="width: 310px;margin: 0 auto;border-bottom: 1px dashed #C8C8C8"></div>
            <div style="display: flex;margin:23px 30px;">
                <div class="hand op sc" @click="toMessage(expertinfo.uuid)">
                    <el-button style="width: 144px;height: 44px;border-radius: 5px;border: 1px solid #FF9800;color: #FF9800;background-color: #FFFFFF;font-size: 18px;padding: 12px 36px;font-weight: bold">私信专家</el-button>
                </div>
                <div class="hand op sc" @click="consultation" style="margin-left: 22px;">
                    <el-button style="width: 144px;height: 44px;border-radius: 5px;border: 1px solid #FF9800;color: #FFFFFF;background-color: #FF9800;font-size: 18px;padding: 12px 36px;font-weight: bold">一键咨询</el-button>
                </div>
            </div>
        </div>
        <el-dialog class="block" :close-on-click-modal="false" :visible.sync="showConsult"  width="1200px">
            <placeAnOrder :expertTopicId="selectedItem"  :expertTopic="selectedItemTitle" :radiolist="radio" :money="selectedItemMoney"></placeAnOrder>
        </el-dialog>
    </div>
</template>
<script>
import placeAnOrder from "./placeAnOrder";
export default {
    components: {placeAnOrder},
    computed: {
        user() {
            return this.$store.state.user
        },
        is_login() {
            return this.$store.state.is_login
        },
        selectedItemTitle() {
            if (this.selectedItem !== null && this.expertinfo.topicList) {
                const selectedTopic = this.expertinfo.topicList.find(topic => topic.id === this.selectedItem);
                return selectedTopic ? selectedTopic.title : '';
            }
            return '';
        },
        selectedItemMoney(){
            if (this.selectedItem !== null && this.expertinfo.topicList) {
                const selectedTopic = this.expertinfo.topicList.find(topic => topic.id === this.selectedItem);
                return selectedTopic ? selectedTopic.money : '';
            }
            return '';
        }
    },
    data() {
        return {
            selectedItem:"",
            options:[],
            radio:1,
            showConsult:false,

            uuid: '',
            nickName: '',
        }
    },
    props: {
        expertinfo:{
            type:Object,
            default() {
                return 0;
            }
        },
    },

    mounted() {

    },

    methods: {
        consultation(){
            if (this.is_login == 0){
                this.$store.commit('setShowLoginPopup', true);
                return
            }
            if(this.user.realNameStatus === 2){
                if (this.selectedItem == ""){
                    this.utils.err('请选择咨询话题')
                    return
                }
                this.showConsult = true
            }else {
                this.utils.err('请先实名认证再一键咨询！')
            }

        },
        relese(){
            this.showConsult = false
        },
        // 私信
        toMessage(uuid) {
            if (this.is_login == 0){
                this.$store.commit('setShowLoginPopup', true);
                return
            }
            if(this.user.realNameStatus === 2){
                const routeData = this.$router.resolve({name: 'News', query: {uuid: uuid}})
                window.open(routeData.href, '_blank');
            }else {
                this.utils.err('请先实名认证再私信专家!')
            }
        },
    }
}
</script>
<style scoped>
 /deep/ .space .el-radio {
    line-height: 45px;
}
/deep/ .space .el-radio__label {
    font-size: 18px;
}
/deep/ .space .el-radio__inner {
    width: 20px;
    height: 20px;
}
/deep/.space .el-radio__inner::after {
    width: 7px;
    height: 7px;
}
/deep/ .block .el-dialog__body{
    padding: 0px 0px!important;
}
</style>