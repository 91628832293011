<template>
    <div style="width: 100%;background: #FFFFFF;border-radius: 5px 0px 0px 5px;box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);">
        <div style="font-size: 22px;font-weight: 600;padding: 22px 20px;">专家咨询话题</div>
        <div class="hand op sc" v-if="hidethetopic" @click="addatopic" style="display: flex;align-items: center;padding-bottom: 10px;width: 100px;padding-left: 20px;">
            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/add_expert.png" style="width: 15px;height: 15px;">
            <span style="color: #1C7EFF;font-size: 17px;margin-left: 5px;">添加话题</span>
        </div>
        <div v-for="(item,index) in expertinfo" :key="index">
            <div v-if="item.topicSwitch == false" class="info_content">
                <div style="display: flex;width: 100%;margin-left: 20px;">
                    <div style="width: 73%;">
                        <div class="namespace">话题名称：<span style="color: #031F88">{{item.title}}</span></div>
                        <div class="namespace">咨询收费标准：<span style="color: #031F88">{{item.money}}</span> 元</div>
                        <div class="namespace">话题介绍：<span style="color: #031F88;">{{item.detail}}</span></div>
                        <div class="namespace" :style="index !== expertinfo.length - 1?'':'margin-bottom: 10px;'">话题状态：<span style="color: red;font-size: 16px;" v-if="item.status === 1">等待审核</span><span style="color: blue;font-size: 16px;"  v-if="item.status === 2">审核通过</span></div>
                    </div>
                    <div style="display: flex;margin-top: 5px;width: 10%;">
                        <div class="hand op sc"  @click="edittopic(item)" style="margin-right: 5px;cursor: pointer">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/edit.png" style="width: 30px;height: 30px">
                        </div>
                        <div class="hand op sc" @click="delExpertTopic(item.id)" style="cursor: pointer">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/delete.png" style="width: 30px;height: 30px">
                        </div>
                    </div>
                </div>
                <div v-if="index !== expertinfo.length - 1" style="border-bottom: 1px dashed #8AB8B8;padding: 10px 0px;width: 100%"></div>
            </div>
            <div v-if="item.topicSwitch === true">
                  <div style="margin-left: 20px;">
                      <div style="width: 100%;">
                          <div style="color: #999999;font-size: 14px;clear: both;margin-top: 16px"><span style="color: red">*</span>话题名称</div>
                          <div style="width: 320px;height: 54px;margin-top: 5px;">
                              <el-input v-model="item.title" placeholder="请输入话题名称" style="width: 100%;height: 54px;"></el-input>
                          </div>
                      </div>
                      <div style="width: 100%;">
                          <div style="color: #999999;font-size: 14px;clear: both;margin-top: 16px"><span style="color: red">*</span>咨询收费标准<span style="font-size: 11px;color: red;"> 单位(元)</span></div>
                          <div style="width: 320px;height: 54px;margin-top: 5px">
                              <el-input v-model="item.money" @input="topiclistMoney($event)" placeholder="请输入咨询收费标准" style="width: 100%;height: 54px;"></el-input>
                          </div>
                      </div>
                      <div style="width: 100%;">
                          <div style="color: #999999;font-size: 14px;clear: both;margin-top: 16px"><span style="color: red">*</span>专业领域</div>
                          <div style="width: 320px;height: 54px;margin-top: 5px">
                              <el-cascader v-model="item.topicArr"  placeholder="请选择话题领域" :options="typeList" style="width: 100%;height: 54px;"></el-cascader>
                          </div>
                      </div>
                      <span style="color: #999999;font-size: 16px;clear: both;"><span style="color: red">*</span>话题介绍</span>
                      <div style="width: 320px;height: 130px;margin-top: 5px;">
                          <el-input type="textarea" :rows="5" v-model="item.detail" placeholder="请输入话题介绍"></el-input>
                      </div>
                      <div style="width: 100%;display: flex;justify-content: center">
                          <div v-if="item.id == undefined" class="hand op sc" @click="cancelfilling(item,index)" style="margin-bottom: 10px">
                              <el-button type="info" size="mini">取消填写</el-button>
                          </div>
                          <div v-else class="hand op sc" @click="cancel(item)" style="margin-bottom: 10px">
                              <el-button type="info" size="mini">取消填写</el-button>
                          </div>
                          <div v-if="item.id == undefined" class="hand op sc" @click="addExpertTopic(item)" style="margin-left: 20px;width: 30%;margin-bottom: 10px">
                              <el-button type="primary" size="mini" style="background: #1c7eff;border: 1px solid #1c7eff;color: #FFFFFF;">保存信息</el-button>
                          </div>
                          <div class="hand op sc" v-else style="margin-left: 20px;margin-bottom: 10px" @click="editExpertTopic(item,item.id,item.title,item.money,item.detail,item.topicArr)">
                              <el-button type="success" size="mini">保存编辑</el-button>
                          </div>
                      </div>
                  </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {

    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    props: {
        expertinfo:{
            type:Array,
            default(){
                return {

                }
            }
        },
    },
    data() {
        return {
            topicList: {
                title: '',
                detail: '',
                money: '',
                topicArr: [],
                topicSwitch:true,
            },
            typeList:[],
            hidethetopic:true,
        }
    },

    mounted() {

    },

    methods: {
        topiclistMoney(){
            this.topicList.money = this.topicList.money.replace(/[^0-9]/g, '');
            this.topicList.money = this.topicList.money.replace(/[^\d]/g, ''); // 仅保留数字
            if (this.topicList.money === '' || parseInt(this.topicList.money) < 1) {
                this.topicList.money = '1';
            }
        },
        edittopic(item) {
            item.topicSwitch = true;
            this.getType();
        },
        // 添加话题
        addatopic(){
            this.topicList.topicSwitch = true;
            this.expertinfo.unshift(this.topicList);
            this.hidethetopic = false;
            this.getType()
        },
        // 获取专业领域
        getType() {
            this.newApi.getTypeList({
                lv: 3
            }).then(res => {
                this.typeList = res.data
            })
        },
        // 取消填写
        cancelfilling(item,index){
            item.topicSwitch = false;
            this.hidethetopic = true;
            this.expertinfo.splice(index, 1); // 删除对应索引的数据
            this.$emit('cancel-edit');
        },
        cancel(item){
            item.topicSwitch = false;
            this.hidethetopic = true;
        },
        // 保存信息
        addExpertTopic(item){
            let that = this;
            if (item.title == ''){
                this.utils.err('话题名称不能为空')
                return
            }
            if (item.money <= 0){
                this.utils.err('话题金额不能为空或者不能为0')
                return
            }
            if (item.topicArr == ""){
                this.utils.err('话题专业领域不能为空')
                return
            }
            if (item.detail == ''){
                this.utils.err('话题内容不能为空')
                return
            }
            let { topicSwitch, ...dataWithoutTopicSwitch } = item;

            item.topicArr = item.topicArr;
            that.topicList = item;
            let data = JSON.parse(JSON.stringify(item));
            data.topicArr = JSON.stringify( item.topicArr)
            // 删除 data 中的 topicSwitch 字段
            delete data.topicSwitch;

            that.newApi.addExpertTopic(data).then(res => {
                if (res.isSuccess === 1) {
                    that.utils.sus('提交成功，请等待审核完成!');
                    item.topicSwitch = false;
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        // 编辑专家话题
        editExpertTopic(item,id,title,money,detail,topicArr){
            let that = this;
            that.newApi.editExpertTopic({
                id:id,
                title:title,
                money:money,
                detail:detail,
                topicArr:JSON.stringify(topicArr),
            }).then(res => {
                that.utils.sus(res.data);
                item.topicSwitch = false;
            }).catch((err) => {
                console.log(err)
            })
        },
        // 删除话题
        delExpertTopic(id){
            var that = this;
            that.newApi.delExpertTopic({
                id: id
            }).then(res => {
                if (res.isSuccess === 1) {
                    that.utils.sus(res.data);
                    that.$emit('cancel-edit');
                }
            }).catch((err) => {
                console.log(err);
            });
        },
    }
}
</script>
<style scoped>
.info_content {
    min-height: 115px;
    font-size: 17px;
    margin-top: 10px;
}
.namespace {
    font-weight: 600;
    line-height: 30px;
    white-space: pre-wrap;
    width: 100%;
}
/deep/.el-textarea__inner {
    resize: none;
}
</style>