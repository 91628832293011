<template>
   <div style="width: 100%;height: 100%">
       <el-tabs v-if="$route.params.uuid == 0" v-model="iseemeName" @tab-click="handleTabClick">
           <el-tab-pane label="全部订单" name="allorders">
               <allOrders :info="0" :expretorderlist="expretorderlist" v-if="iseemeName === 'allorders'"></allOrders>
           </el-tab-pane>
           <el-tab-pane label="正在咨询订单" name="consultationorder">
               <allOrders :info="1" :expretorderlist="expretorderlist" v-if="iseemeName === 'consultationorder'"></allOrders>
           </el-tab-pane>
           <el-tab-pane label="历史订单" name="historicalorder">
               <allOrders :info="6" :expretorderlist="expretorderlist" v-if="iseemeName === 'historicalorder'"></allOrders>
           </el-tab-pane>
           <el-tab-pane label="用户评价" name="userevaluation">
               <userEvaluation v-if="iseemeName === 'userevaluation'" :evaluatelist="evaluatelist"></userEvaluation>
               <NoData v-if="evaluatelist.length === 0"></NoData>
           </el-tab-pane>
       </el-tabs>
       <el-tabs v-else v-model="youseehimName" @tab-click="handleTabClick">
           <el-tab-pane label="专家话题" name="expertTopic" style="width: 100%">
               <expertTopic :expertinfo="expertinfo"></expertTopic>
           </el-tab-pane>
           <el-tab-pane label="专家订单" name="allorders">
               <allOrders :info="0" v-if="iseemeName === 'allorders'" :expretorderlist="expretorderlist"></allOrders>
           </el-tab-pane>
           <el-tab-pane label="专家信息" name="expertInformation" style="width: 100%;max-width: 100%;">
               <div style="display: flex;justify-content: center;">
                   <div class="title-content">
                       <div class="title-img">
                           <img class="title-img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/Expertsocialtitle.png">
                       </div>
                       <div class="title-name">专家社会头衔</div>
                   </div>
               </div>
               <div v-for="(picker,ind) in expertinfo.titleInfo" :key="ind">
                   <div class="info_content">
                       <div class="namespace">社会头衔名称：{{picker.title}}</div>
<!--                       <div v-if="picker.images.length > 0">-->
<!--                           <div class="namespace">头衔证书（不对外展示）</div>-->
<!--                           <div style="display: flex">-->
<!--                               <div  v-if="picker.images[0] !== null && picker.images[0] !== ''" style="width: 500px;height: 300px;"><img style="width: 500px;height: 300px;border-radius: 5px;object-fit: cover;" :src="picker.images[0]"></div>-->
<!--                               <div  v-if="picker.images[1] !== null && picker.images[1] !== ''" style="width: 500px;height: 300px;margin-left: 40px;"><img style="width: 500px;height: 300px;border-radius: 5px;object-fit: cover;" :src="picker.images[1]"></div>-->
<!--                           </div>-->
<!--                       </div>-->
                       <div v-if="ind !== expertinfo.titleInfo.length - 1" style="border-bottom: 1px dashed #8AB8B8;padding: 20px 10px;width: 100%;margin: 0 auto"></div>
                   </div>
               </div>

               <div style="display: flex;justify-content: center;">
                   <div class="title-content">
                       <div class="title-img">
                           <img class="title-img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/Expertperformane%20andresume.png">
                       </div>
                       <div class="title-name">专家业绩和履历</div>
                   </div>
               </div>
               <div class="info_content">
                   <div class="namespace" v-html="expertinfo.content"></div>
               </div>

               <div style="display: flex;justify-content: center;">
                   <div class="title-content">
                       <div class="title-img">
                           <img class="title-img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/Expertmonograph.png">
                       </div>
                       <div class="title-name">专家专著论文</div>
                   </div>
               </div>
               <div v-for="(value,name) in expertinfo.monograph" :key="name">
                   <div class="info_content">
                       <div class="namespace">著作名称：{{value.name}}</div>
                       <el-descriptions>
                           <el-descriptions-item label="出版社">{{value.form}}</el-descriptions-item>
                           <el-descriptions-item label="ISBN">{{value.ISBN}}</el-descriptions-item>
                           <el-descriptions-item label="出版日期">{{value.time}}</el-descriptions-item>
                       </el-descriptions>
<!--                       <div v-if="value.images.length > 0">-->
<!--                           <div class="namespace">著作封面（不对外展示）</div>-->
<!--                           <div style="display: flex">-->
<!--                               <div v-if="value.images[0] !== undefined && value.images[0] !== ''" style="width: 500px;height: 300px;"><img style="width: 500px;height: 300px;border-radius: 5px;object-fit: cover;" :src="value.images[0]"></div>-->
<!--                               <div v-if="value.images[1] !== undefined && value.images[1] !== ''" style="width: 500px;height: 300px;margin-left: 40px;"><img style="width: 500px;height: 300px;border-radius: 5px;object-fit: cover;" :src="value.images[1]"></div>-->
<!--                           </div>-->
<!--                       </div>-->
                       <div v-if="name !== expertinfo.monograph.length - 1" style="border-bottom: 1px dashed #8AB8B8;padding: 20px 10px;width: 100%;margin: 0 auto"></div>
                   </div>
               </div>
               <div v-if="expertinfo.paper && expertinfo.paper.length" style="border-bottom: 1px dashed #8AB8B8;padding: 20px 10px;width: 85%;margin: 0 auto"></div>
               <div v-for="(block,code) in expertinfo.paper" :key="code">
                   <div class="info_content">
                       <div class="namespace">论文标题：{{block.title}}</div>
                       <el-descriptions>
                           <el-descriptions-item label="发表刊物">{{block.publication}}</el-descriptions-item>
                           <el-descriptions-item label="刊号">{{block.publicationNo}}</el-descriptions-item>
                           <el-descriptions-item label="出版日期">{{block.time}}</el-descriptions-item>
                       </el-descriptions>
<!--                       <div v-if="block.images.length > 0">-->
<!--                           <div class="namespace">论文封面（不对外展示）</div>-->
<!--                           <div style="display: flex">-->
<!--                               <div v-if="block.images[0] !== null && block.images[0] !== ''" style="width: 500px;height: 300px;"><img style="width: 500px;height: 300px;border-radius: 5px;object-fit: cover;" :src="block.images[0]"></div>-->
<!--                               <div v-if="block.images[1] !== null && block.images[1] !== ''" style="width: 500px;height: 300px;margin-left: 40px;"><img style="width: 500px;height: 300px;border-radius: 5px;object-fit: cover;" :src="block.images[1]"></div>-->
<!--                           </div>-->
<!--                       </div>-->
                       <div v-if="code !== expertinfo.paper.length - 1" style="border-bottom: 1px dashed #8AB8B8;padding: 20px 10px;width: 100%;margin: 0 auto"></div>
                   </div>
               </div>

               <div style="display: flex;justify-content: center;">
                   <div class="title-content">
                       <div class="title-img">
                           <img class="title-img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/law.png">
                       </div>
                       <div class="title-name">是否有参与制定本专业法律法规、标准规范情况与其他佐证</div>
                   </div>
               </div>
               <div class="info_content">
                   <div class="namespace">{{expertinfo.other}}</div>
               </div>
               <!--  专家话题-->
               <div style="display: flex;justify-content: center;">
                   <div class="title-content">
                       <div class="title-img">
                           <img class="title-img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/topic.png">
                       </div>
                       <div class="title-name">专家话题</div>
                   </div>
               </div>
               <div class="info_content"  v-for="(it,align) in expertinfo.topicList" :key="align">
                   <div class="namespace">话题名称：<span style="color: #031F88">{{it.title}}</span></div>
                   <div class="namespace">咨询收费标准：<span style="color: #031F88">{{it.money}}</span> 元</div>
                   <div class="namespace">话题介绍：<span style="color: #031F88">{{it.detail}}</span></div>
                   <div v-if="align !== expertinfo.topicList.length - 1" style="border-bottom: 1px dashed #8AB8B8;padding: 20px 10px;width: 100%;margin: 0 auto"></div>
               </div>
           </el-tab-pane>
           <el-tab-pane label="用户评价" name="userevaluation">
               <userEvaluation  :evaluatelist="evaluatelist"></userEvaluation>
               <NoData v-if="evaluatelist.length === 0"></NoData>
           </el-tab-pane>
       </el-tabs>
   </div>
</template>
<script>
import allOrders from "./allOrders"
import userEvaluation from "./userEvaluation"
import expertTopic from "./expertTopic"
import waitingForApproval from "../componentsBecome/waitingForApproval";
import NoData from "../../../components/common/NoData";
export default {
    components: {allOrders, userEvaluation, expertTopic,waitingForApproval,NoData},
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    props: {
        expertinfo:{
            type:Object,
            default(){
                return {

                }
            }
        },
    },
    data() {
        return {
          orderList:[
              {type:0, name:'全部订单'},
              {type:1, name:'正在咨询订单'},
              {type:2, name:'历史订单'},
              {type:3, name:'用户评价'},

          ],
          orderType: 0,

          iseemeName:'allorders',
          youseehimName:'expertTopic',

          expretorderlist:[],
          evaluatelist:[],
        }
    },

    mounted() {
     this.initAllOrders()
    },

    methods: {
        handleTabClick(tab) {
            if (tab.name === 'allorders') {
                this.initAllOrders();
            } else if (tab.name === 'consultationorder') {
                this.initConsultationOrders();
            } else if (tab.name === 'historicalorder') {
                this.initHistoricalOrders();
            } else if (tab.name === 'userevaluation') {
                this.initUserEvaluation();
            }
        },
        // 调用初始化全部订单接口
        initAllOrders() {
            let that = this;
            var params = {}
            params.status = 0;
            params.page = 1;
            params.pageSize = 10;
            if(that.$route.params.uuid != 0){
                params.expert = that.$route.params.uuid
            }
            that.newApi.getExpretOrderList(params).then(res => {
                that.expretorderlist = res.data
            }).catch((err) => {
                console.log(err)
            })
        },
        // 调用初始化全部订单接口
        initConsultationOrders() {
            let that = this;
            var params = {}
            params.status = 1;
            params.page = 1;
            params.pageSize = 10;
            if(that.$route.params.uuid != 0){
                params.expert = that.$route.params.uuid
            }
            that.newApi.getExpretOrderList(params).then(res => {
                that.expretorderlist = res.data
            }).catch((err) => {
                console.log(err)
            })
        },
        // 调用初始化历史订单接口
        initHistoricalOrders() {
            let that = this;
            var params = {}
            params.status = 6;
            params.page = 1;
            params.pageSize = 10;
            if(that.$route.params.uuid != 0){
                params.expert = that.$route.params.uuid
            }
            that.newApi.getExpretOrderList(params).then(res => {
                that.expretorderlist = res.data
            }).catch((err) => {
                console.log(err)
            })
        },
        // 调用初始化用户评价接口
        initUserEvaluation() {
            let that = this;
            var params = {}
            params.type = 1;
            params.topicId = "";
            if (that.$route.params.uuid != 0){
                params.expert = this.$route.params.uuid;
            }else {
                params.expert = this.user.uuid
            }
            this.newApi.getExpertEvaluateList(params).then(res => {
                this.evaluatelist = res.data
            })
        }
    }
}
</script>

<style scoped>
/deep/ .el-tabs__nav {
    margin-left: 44px;
}
/deep/ .el-tabs__item.is-active {
    color:#2970FF!important;
}
/deep/ .el-tabs__active-bar {
    background-color:#2970FF!important ;
    font-weight: 400 !important;
}
/deep/ .el-tabs__item {
    font-size: 18px;
}
.parent-container {
    display: flex;
    justify-content: center;
    margin-top: -30px;
}
.title-content {
    width: 95%;
    height: 60px;
    background: url('https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertconnection/title_content.png');
    margin-top: 40px;
    background-size: cover;
}
.title-img {
    width: 40px;
    height: 40px;
    margin-top: 6px;
    margin-left: 20px;
    float: left;
}
.title-name {
    font-size: 24px;
    font-weight: 600;
    float: left;
    color: #FFFFFF ;
    margin-left: 30px;
    margin-top: 16px;

}
.info_content {
    width: 90%;
    font-size: 20px;
    margin: 30px auto;
}
.namespace {
    font-weight: 600;
    line-height: 40px;
    white-space: pre-wrap;
}
/deep/ .el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
    font-weight: 600;
    line-height: 40px;
    white-space: pre-wrap;
    font-size: 20px;
    color: #1a1a1a;
}
</style>