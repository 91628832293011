<template>
    <div style="width: 100%;">
        <el-table
            :data="expretorderlist"
            stripe
            style="width: 95%;margin: 0 auto;border-radius: 5px;">
            <el-table-column v-if="$route.params.uuid == 0" label="权限">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: center;align-items: center;">
                        <div v-if="scope.row.isOpen == 0" style="font-size: 18px;cursor: pointer;">不公开</div>
                        <div v-if="scope.row.isOpen == 1" style="font-size: 18px;cursor: pointer;">公开</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="下单日期" width="200">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: center;align-items: center;">
                        <div style="font-size: 18px;cursor: pointer;">{{scope.row.creatTime}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="咨询标题"  >
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: center;align-items: center;">
                        <div style="font-size: 18px;cursor: pointer; display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;text-overflow: ellipsis;margin-left: 30px">{{scope.row.title}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="委托人" v-if="$route.params.uuid != 0">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: center;align-items: center;">
                        <div style="font-size: 18px;cursor: pointer; display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;text-overflow: ellipsis;margin-left: 30px">{{scope.row.principalName}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="咨询方式" >
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: center;align-items: center;margin-left: 40px;">
                        <div style="font-size: 18px;cursor: pointer;" v-if="scope.row.type == 1">视频咨询</div>
                        <div style="font-size: 18px;cursor: pointer;" v-if="scope.row.type == 2">在线函复</div>
                        <div style="font-size: 18px;cursor: pointer;" v-if="scope.row.type == 3">现场会议</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="订单状态">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: center;align-items: center;margin-left: 50px;font-weight: bold">
                        <div v-if="scope.row.status == 0" style="font-size: 18px;cursor: pointer;color: #2970FF">已拒绝</div>
                        <div v-if="scope.row.status == 1" style="font-size: 18px;cursor: pointer;color: #FF9900">待接单</div>
                        <div v-if="scope.row.status == 2" style="font-size: 18px;cursor: pointer;color: #F44336">待支付</div>
                        <div v-if="scope.row.status == 3" style="font-size: 18px;cursor: pointer;color: #8BC34A">进行中</div>
                        <div v-if="scope.row.status == 4" style="font-size: 18px;cursor: pointer;color: #2970FF">待确认</div>
                        <div v-if="scope.row.status == 5" style="font-size: 18px;cursor: pointer;color: #FF9900">待评价</div>
                        <div v-if="scope.row.status == 6" style="font-size: 18px;cursor: pointer;color: #F44336">完成</div>
                        <div v-if="scope.row.status == 7" style="font-size: 18px;cursor: pointer;color: #FC2519">退款申请中</div>
                        <div v-if="scope.row.status == 8" style="font-size: 18px;cursor: pointer;color: #1D82FE">退款完成</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <div class="hand op sc" @click="a('/expert/order/'+scope.row.orderNo)" style="display: flex;justify-content: center;align-items: center;margin-left: 30px;">
                        <el-button size="small" style="color: #2970FF;border: 1px solid #2970FF;font-size: 16px;">查看详情</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <NoData v-if="expretorderlist.length === 0"></NoData>
    </div>
</template>
<script>
import NoData from "../../../components/common/NoData";
export default {
    components: {
        NoData
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    props: {
        expertinfo:{
            type:Object,
            default(){
                return {

                }
            }
        },
        info:{
            type:Number,
            default(){
                return 0
            }
        },
        expretorderlist: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {

        }
    },

    mounted() {
    },

    methods: {

    }
}
</script>
<style scoped>
/deep/ .el-table th.el-table__cell>.cell {
    font-size:16px;
    margin-left: 60px;
}
/deep/ .el-table th.el-table__cell.is-leaf  {
    background-color: #2970FF!important;
    color: #FFFFFF!important;

}
/deep/ .el-table thead {
    border-radius: 5px 0 0 5px!important;
}
</style>
