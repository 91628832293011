<template>
    <div style="width: 100%;height: 690px;">
        <div style="display: flex;justify-content: center"><span style="font-size: 20px;color: #444444;font-weight: bold">选择想要该专家加入的ROOM</span></div>
        <el-divider style="width: 100%"></el-divider>
        <div style="display: flex;justify-content: space-around">
            <div style="width: 400px;height: 500px;border: 1px solid #CCCCCC;border-radius: 5px 5px 5px 5px;">
                <div style="font-size: 18px;color: #444444;font-weight: 600;padding: 14px 20px">ROOM列表</div>
                <div class="scrollable" style="width: 100%;max-height: 390px;">
                    <div v-for="(it,index) in roomList" :key="index" style="margin-left: 20px;">
                        <el-checkbox v-model="selectedRooms" :label="it" :disabled="it.isInRoom === 1" style="font-weight: 600; line-height: 35px;">
                            <div style="display: inline-block; width: 350px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ it.name }}</div>
                        </el-checkbox>
                    </div>
                </div>
            </div>
            <div style="width: 400px;height: 500px;border: 1px solid #CCCCCC;border-radius: 5px 5px 5px 5px;">
                <div style="font-size: 18px;color: #444444;font-weight: 600;padding: 14px 20px">已选择ROOM</div>
                <div class="scrollable" style="max-height: 450px;">
                    <div v-for="(room, roomIndex) in selectedRooms" :key="roomIndex" style="margin-left: 20px;margin-bottom: 10px;">
                        <el-tag style="font-size: 16px; max-width: 350px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" :closable="true" effect="dark" closable @close="removeRoom(room)">{{ room.name }}</el-tag>
                    </div>
                </div>
            </div>
        </div>
        <div class="hand op sc" @click="inviteJoinRoom" style="width: 188px;height: 49px;margin: 20px auto">
            <el-button style="background: #F6F6F6;width: 188px;height: 49px;color:#999999;border: 1px solid #BDBFC1;font-size: 18px;font-weight: 600;">确认邀请专家</el-button>
        </div>
    </div>
</template>
<script>
export default {
    components: {

    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    data() {
        return {
            roomList:[],
            selectedRooms: [],
        }
    },
    props: {

    },
    watch: {

    },
    mounted() {
        this.getMyRoomListAndOtherUser();
    },

    methods: {
        //  获取专家中邀请专家进入room的room列表
        getMyRoomListAndOtherUser(){
            var that = this;
            this.newApi.getMyRoomListAndOtherUser({
                otherUserUuid:that.$route.params.uuid
            }).then(res => {
                if(res.isSuccess == 1){
                    this.roomList = res.data
                }
            })
        },

        removeRoom(room) {
            const index = this.selectedRooms.indexOf(room);
            if (index !== -1) {
                this.selectedRooms.splice(index, 1);
            }
        },

        inviteJoinRoom(){
            this.selectedRooms.forEach(room => {
                this.newApi.inviteJoinRoom({
                    roomId: room.roomId, // 传入当前房间的ID
                    userList: JSON.stringify([this.$route.params.uuid])
                }).then(res => {
                    if (res.isSuccess == 1) {
                        this.utils.sus(res.data);
                        this.$parent.$parent.inviteexpertscode();
                    }
                });
            });
        },
    }
}
</script>

<style scoped>
/deep/ .el-checkbox__inner {
    width: 16px;
    height: 16px;
    margin-bottom: 7px;
}
/deep/ .el-checkbox__inner::after {
    width: 4px;
    height: 9px;
}

/deep/ .el-checkbox__label {
    font-size: 17px;
}

.scrollable {
    overflow-y: auto; /* 让内容超过150px高度时可以滚动 */
    scrollbar-width: none; /* 隐藏滚动条 Firefox */
    -ms-overflow-style: none; /* 隐藏滚动条 IE/Edge */
}
.scrollable::-webkit-scrollbar {
    display: none; /* 隐藏滚动条 Chrome/Safari */
}
</style>