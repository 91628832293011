<template>
	<div class="no-data-container">
        <el-empty description="暂无数据"></el-empty>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			};
		},
	};
</script>

<style scoped>
	.no-data-container {
		display: flex;
		justify-content: center;
		align-items: center;
	}
   /deep/ .el-empty__description p{
        font-size: 18px;
    }
</style>
