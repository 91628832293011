<template>
    <div style="width: 100%">
        <div v-for="(item,index) in evaluatelist" :key="index">
            <div style="display: flex">
                <div style="margin: 20px 20px 0 30px;">
                    <div style="width: 54px;height: 54px;">
                        <img style="width: 54px;height: 54px;border-radius: 50%" :src="item.userHead">
                    </div>
                </div>
                <div style="margin-top: 20px;">
                    <div style="display: flex">
                        <div style="font-size: 18px;">{{item.userName}}</div>
                        <div style="width: 17px;height: 17px;margin-top: 6px;margin-left: 8px;"><img style="width: 17px;height: 17px;" src="@/assets/imgs/index/vipBig.png"></div>
                    </div>
                    <div><el-rate show-text disabled :value="item.score" :style="{ color: '#E4393C !important' }"></el-rate></div>
                    <div style="font-size: 16px;color: #666666;font-weight: 600;line-height: 24px;margin-top: 5px;width: 95%;">{{item.content}}</div>
                    <div style="color: #999999;font-size: 13px;margin-top: 5px;" :style="index !== evaluatelist.length - 1?'':'margin-bottom: 20px'">{{item.creationTime}}</div>
                </div>
            </div>
            <div v-if="index !== evaluatelist.length - 1" style="width: 95%;margin: 20px auto;border-bottom: 1px dashed #C8C8C8;"></div>
        </div>
    </div>
</template>
<script>
export default {
    components: {

    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    props: {
        evaluatelist:{
            type:Array,
            default() {
                return [];
            }
        },
    },
    data() {
        return {

        }
    },

    mounted() {

    },

    methods: {

    }
}
</script>
<style scoped>
/deep/ .el-rate__text {
    margin-left: 2px !important;
    margin-top: 5px !important;
    color: #E4393C !important;
    font-size: 14px !important;
    font-weight: bold !important;
}
/deep/ .el-rate {
    margin-top: 5px !important;
}
/deep/ .el-rate__icon el-icon-star-on {
    color:#E4393C !important;
}
</style>