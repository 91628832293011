<template>
    <div style="width: 100%;">
        <div v-for="number in [0,1]">
            <div v-for="(item,index) in expertinfo.topicList" :key="index" v-if="index%2===number" style="float: left">
                <div style="width: 520px;height: 280px;background: #F5F6FE;border-radius: 10px;margin-left: 30px;margin-top: 20px">
                    <div style="color:#FF8F1F;font-size: 20px;padding: 26px;font-weight: bold">#<span>{{item.title}}</span></div>
                    <div class="scrollable" style="font-size: 18px;line-height: 30px;width: 468px;margin: 0 auto;max-height: 150px;cursor: pointer">{{item.detail}}</div>
                    <div style="font-size: 16px;font-weight: bold;margin: 16px 28px">咨询收费标准：<span style="color: #FF0000">{{item.money}}元</span></div>
                </div>
                <div style="clear: both;"></div>
            </div>
        </div>
        <NoData v-if="expertinfo.topicList && expertinfo.topicList.length === 0"></NoData>
    </div>
</template>
<script>
import NoData from "../../../components/common/NoData";
export default {
    components: {NoData},
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    props: {
        expertinfo:{
            type:Object,
            default() {
                return 0;
            }
        },
    },
    data() {
        return {

        }
    },

    mounted() {

    },

    methods: {

    }
}
</script>
<style scoped>
.scrollable {
    overflow-y: auto; /* 让内容超过150px高度时可以滚动 */
    scrollbar-width: none; /* 隐藏滚动条 Firefox */
    -ms-overflow-style: none; /* 隐藏滚动条 IE/Edge */
}

.scrollable::-webkit-scrollbar {
    display: none; /* 隐藏滚动条 Chrome/Safari */
}
</style>