<template>
    <div>
        <div v-for="(item,index) in expertlist" :key="index" style="width: 370px;height: 360px;background: #FFFFFF;box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);border-radius: 5px;margin-bottom: 10px;">
            <div style="padding-top: 24px;">
                <div style="font-size: 18px;font-weight: 600;margin-left: 30px;float: left">推荐专家</div>
                <div class="hand op sc" style="font-size: 16px;display: flex;cursor: pointer;float: right;margin-right: 30px;">
                    <div style="width: 20px;height: 20px;display: flex;justify-content: center;align-items:center;">
                        <img style="width: 16px;height: 16px;" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertSpace/changeit.png">
                    </div>
                    <div style="color: #999999">换一换</div>
                </div>
            </div>
            <div style="clear: both;display: flex;padding: 20px 0 16px 30px">
                <div style="width: 50px;height: 50px;">
                    <img style="width: 50px;height: 50px;border-radius: 50%" :src="item.headIco">
                </div>
                <div style="margin-left: 10px;">
                    <div style="display: flex;">
                        <div style="font-weight: 600;">{{item.nickName}}</div>
                        <div style="margin-left: 10px;">
                            <img style="width: 49px;height: 20px;" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertSpace/inoc.png">
                        </div>
                    </div>
                    <div style="display: flex;font-size: 14px;color:#AAAAAA;margin-top: 5px;">
                      <div>乌鲁木齐</div>
                      <div style="padding: 0 10px;">|</div>
                      <div>城市规划</div>
                    </div>
                </div>
            </div>
            <div style="clear: both;width: 304px;margin: 10px auto"><div style="font-size:15px;color: #999999;line-height: 20px;">专家简介：擅长城乡总体规划、详细规划、 各类专项规划、课题研究</div></div>
            <div style="width: 304px;margin: 0 auto;border-bottom: 1px dashed #C8C8C8"></div>
            <div style="clear: both;margin-left: 30px;">
                <div v-for="(item,index) in topiclist" :key="index" >
                    <div style="font-size: 16px;line-height: 30px;">{{item.name}}</div>
                </div>
            </div>
            <div style="display: flex;margin:20px 30px;">
                <div class="hand op sc" @click="toMessage(item.uuid)" style="width: 96px;height: 30px;">
                    <el-button style="width: 96px;height: 30px;border-radius: 20px;background-color: #FFFFFF;border: 1px solid #FF9800;color:#FF9800;padding: 6px 16px;font-size: 16px;font-weight: bold;">私信专家</el-button>
                </div>
                <div class="hand op sc" @click="collectionExpert(item.uuid)" style="width: 96px;height: 30px;margin: 0 11px;">
                    <el-button v-if="item.isFollow == 0" style="width: 96px;height: 30px;border-radius: 20px;background-color: #FFFFFF;border: 1px solid #2970FF;color:#2970FF;padding: 6px 20px;font-size: 16px;font-weight: bold;">+关注</el-button>
                    <el-button v-if="item.isFollow == 1" style="width: 96px;height: 30px;border-radius: 20px;background-color: #FFFFFF;border: 1px solid #2970FF;color:#2970FF;padding: 6px 20px;font-size: 16px;font-weight: bold;">已关注</el-button>
                </div>
                <div class="hand op sc" @click="viewhomepage(item.uuid)" style="width: 96px;height: 30px;">
                    <el-button style="width: 96px;height: 30px;border-radius: 20px;background-color: #2970FF;border: 1px solid #2970FF;color:#FFFFFF;padding: 6px 16px;font-size: 16px;font-weight: bold;">查看首页</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        is_login() {
            return this.$store.state.is_login
        },
    },
    data() {
        return {
            topiclist:[
                {index:'0',name:'#国土空间规划/生态修复一站式技术服务'},
                {index:'1',name:'#城乡生活垃圾处理设施专项规划咨询'},
                {index:'2',name:'#区域性产业分析和战略研究'},
            ],
            expertlist:[],

            uuid: '',
            nickName: '',
        }
    },

    mounted() {
        if (this.$route.params.uuid != 0){
            this.getHotExpertList()
        }
    },

    methods: {
        getHotExpertList(){
            var that = this;
            that.newApi.getHotExpertList({}).then(res => {
                that.expertlist = res.data;
            }).catch((err) => {
                console.log(err)
            })
        },
        collectionExpert(uuid){
            if (this.user.realNameStatus === 2) {
                this.newApi.collectionExpert({
                    uuid: uuid
                }).then(res => {
                    if (res.isSuccess == 1) {
                        this.utils.sus(res.data)
                        this.getHotExpertList()
                    }
                })
            }else {
                this.utils.err('请先实名认证再关注专家！')
            }
        },
        // 私信
        toMessage(uuid) {
            if (this.is_login == 0){
                this.$store.commit('setShowLoginPopup', true);
                return
            }
            if(this.user.realNameStatus === 2) {
                const routeData = this.$router.resolve({name: 'News', query: {uuid: uuid}})
                window.open(routeData.href, '_blank');
            }else {
                this.utils.err('请先实名认证再私信专家!')
            }
        },

        // 查看专家空间
        viewhomepage(uuid){
           window.open('/expert/space/'+uuid)
        }


    }
}
</script>